import { addons } from '@storybook/addons';

import ldsTheme from './lds_storybook_theme';

addons.setConfig({
  theme: ldsTheme,
  sidebar: {
    collapsedRoots: ['baseweb'],
  },
});
